import { render, staticRenderFns } from "./dilmah-gourmet-tea.vue?vue&type=template&id=0c06ec3e&scoped=true&"
import script from "./dilmah-gourmet-tea.vue?vue&type=script&lang=js&"
export * from "./dilmah-gourmet-tea.vue?vue&type=script&lang=js&"
import style0 from "./dilmah-gourmet-tea.vue?vue&type=style&index=0&id=0c06ec3e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c06ec3e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerTypeProduct: require('/app/components/content-elements/banner-type-product.vue').default})
